<template>
    <section>
        <div class="container">
            <div class="row my-4 info">
                <p>Nota: Para acceder a los permisos de la ruta de cada modulo debe dar clic sobre el nombre del módulo</p>
            </div>
            <tabla-permisos :heads='getMenuPrincipal' :rutasActive='rutasActive' />
        </div>
    </section>
</template>
 
<script>
import redirect from './../components/redirect.vue'
import tablaPermisos from './../components/table.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
    components:{
        redirect,
        tablaPermisos,
    },
    data() {
      return {
        rutasActive: true,
        // btnAdd: {
        //     isVisible: false,
        //     title: "",
        //     type: null 
        // }
      }
    },
    mounted(){
    },
    computed: {
        ...mapGetters({
            menu: 'configurar/permisos/menus/menus', 
        }),
        menus: {
           get(){
             return this.menu
           },
           set(newName){
             return newName
           } 
        },
        getMenuPrincipal() { 
            console.log("🚀 ~ file: index.vue:48 ~ getMenuPrincipal ~ this.menus", this.menus)
            return this.menus;
        }
    },
    async created(){
        await this.getMenus()
        this.getBreadcumbs([ 'configurar.permisos' ]);
    },
    methods:{
        ...mapActions({
            getMenus: 'configurar/permisos/menus/Action_get_menus', 
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
    }
}
</script>

<style lang="scss" scoped>
.info{
    display: flex;
    justify-content: center;
    font-style: italic;
    font-size: 14px;
}
</style>